import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { Button, Typography } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

// Initialize Firebase with your configuration
const firebaseConfig = {
  apiKey: "AIzaSyClp0PI3nIy5-mvF53L48osgGIOJ94aw2A",
  authDomain: "vamosdeferias.firebaseapp.com",
  databaseURL: "https://vamosdeferias.firebaseio.com",
  projectId: "vamosdeferias",
  storageBucket: "vamosdeferias.appspot.com",
  messagingSenderId: "391560625877",
  appId: "1:391560625877:web:c2ed514a1c4582ec632c5c",
  measurementId: "G-R61EJ3K2X3"
};

firebase.initializeApp(firebaseConfig);

// Create a reference to the database
const db = firebase.database();

// Create a handler function for the value change


// Create a localizer for the calendar
const localizer = momentLocalizer(moment);

function App() {
  const eventPropGetter=(event) => {
    
    const backgroundColor = event.color
    return { style: { backgroundColor } }
  }
  const handleChange = (event) => {
    // Set the state with the new value
    setValue(event.target.value);
  };
  // Create a state to store the events
  const [value, setValue] = useState('Tudo');

  const [events, setEvents] = useState([]);

  // Fetch the events from Firebase once the component mounts
  useEffect(() => {
    // Get the data from the 'ics' node in the database
    db.ref('listings').once('value', (snapshot) => {
      // Convert the snapshot value to an array of events
      const data = snapshot.val()
      const eventsArray = data ? data : {};
      const keys = Object.keys(eventsArray);
      const colors = ["black", "blue", "green", "orange"]
      keys.forEach((key,index)=>{
          eventsArray[key].forEach(rec => {
          const startDate = rec.start.split("-");
          const endDate = rec.end.split("-");
          rec.title = `${key}_${rec.title}`
          rec.start = new Date(startDate[0], startDate[1] - 1, startDate[2]);
          rec.end = new Date(endDate[0], endDate[1] - 1, endDate[2]);
          rec.color= colors[index];
          return rec
        })
      })
      
      // Set the events state with the fetched data
      console.log(eventsArray)
      setEvents(eventsArray);
    });
  }, []);
  const listings = ["Tudo", ...Object.keys(events)];
  let result = [];
  if(value==="Tudo"){
    Object.keys(events).forEach((key)=>{
      result = [...result, ...events[key]]
    })
  }else{
    result = events[value]
  }
  console.log(result)
  return (
    <div className="App">
      <Typography variant="h4" align="center">
        Calendario Airbnb
      </Typography>
      <FormControl style={{width:"100%"}}>
        <InputLabel id="demo-select-label">Selecionar Listagem</InputLabel>
        <Select
          labelId="demo-select-label"
          id="demo-select"
          value={value}
          defaultValue={value}
          onChange={handleChange}
        >
          {listings.map(listing=><MenuItem value={listing}>{listing}</MenuItem>)}
        </Select>
      </FormControl>
      <Calendar
      eventPropGetter={eventPropGetter}
        localizer={localizer}
        events={result}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
}

export default App;